<template>
	<div class="d-flex pt-5 px-20 position-absolute vh-75 my-auto top-0 bottom-0 start-0 end-0"
		 style="background-position: 50% 0%; background-repeat: no-repeat; background-size: contain;"
		 :style="'background-image: url('+this.backgroundImage+')'">
		<div class="position-absolute start-0 end-0 bottom-0 d-flex flex-column align-content-center">
			<h1 class="display-1 text-center text-warning fw-bold">Ошибка 402</h1>
			<h2 class="text-center text-dark">Доступ ограничен!</h2>
			<span class="text-center text-dark-gray">На вашем тарифе данный раздел недоступен.
					</span>
			<router-link to="/" class="d-inline-flex align-self-center mx-auto link link-dashed mt-4">На главную</router-link>
		</div>
	</div>
</template>

<script>

import CommonService from "@/services/CommonService";

export default {
	name: "restricted-access",
	data() {
		return {
			title: "Ошибка 402",
			backgroundImage: require('@/assets/svg/restricted-bg.svg')
		};
	},
	methods: {
	},
	components: {
	},
	mounted() {
		CommonService.debounce(() => window.location.href = '/login', 2000);
	},
	watch: {
	}
};
</script>